import React, { Component } from 'react'
import Filter from './Filter';

class History extends Component {
    render() {
        return (
            <div>
                <Filter/>
            </div>
        )
    }
}

export default History;